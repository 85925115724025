import React, { useMemo, useState } from 'react';
import { Box, Chip, Popper } from '@mui/material';
import moment from 'moment';
import DateRangeWithShortcuts from './DateRangeWithShortcuts';

export default function CustomDateRangePicker({
  value,
  onSave,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const formattedDateRange = useMemo(() => {
    const forattedStartDate = moment.utc(value?.[0]).format('l');
    const forattedEndDate = moment.utc(value?.[1]).format('l');
    return `${forattedStartDate} - ${forattedEndDate}`;
  }, [value]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={48}
      width="100%"
    >
      <Chip
        sx={{ fontSize: '1rem', height: 48, width: '100%' }}
        label={formattedDateRange}
        onClick={(event) => handleClick(event)}
      />
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="bottom-start"
        sx={{
          zIndex: 1,
        }}
      >
        <DateRangeWithShortcuts
          value={value}
          buttons={[
            {
              label: 'Cancel',
              onClick: () => setOpen(false),
            },
            {
              label: 'Accept',
              type: 'accept',
              onClick: () => { setOpen(false); onSave(); },
            },
          ]}
          {...rest}
        />
      </Popper>
    </Box>
  );
}
