import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import {
  Autocomplete as MUIAutocomplete,
  Checkbox,
  useTheme,
  TextField,
  Chip,
  Button,
  ListItemText,
  MenuItem,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UncheckedCheckboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

export default function Autocomplete({
  groupBy,
  limitTags,
  options,
  allOption,
  onlyOption,
  getGroupLabel,
  getOptionLabel,
  getOptionDisabled,
  isOptionEqualToValue,
  label,
  labelKey,
  valueKey = 'value',
  isDisabled,
  isReadOnly,
  placeholder,
  value,
  onChange,
  onInputChange,
  loading,
  noOptionsText,
  renderTags,
  multiple,
  checkbox,
  getOptionInListLabel,
  error,
  disableClearable,
  disableCloseOnSelect,
  onClose,
  onOpen,
  onClear,
  size,
  meta,
  ...rest
}) {
  const theme = useTheme();
  const { t } = useTranslation('ui');
  const [inputValue, setInputValue] = useState('');
  const onlyOptionRef = useRef(null);

  const optionsToRender = useMemo(() => {
    if (!allOption) return options || [];
    return [{ [labelKey]: 'All', [valueKey]: 'all' }, ...options];
  }, [options, allOption]);

  const onRenderTags = useCallback((value, getTagProps, ownerState) => {
    if (renderTags) return renderTags(value, getTagProps, ownerState);
    if (limitTags) {
      const numTags = value.length;
      const isAllOption = value?.find((item) => item[valueKey] === 'all');
      if (isAllOption) {
        const isAllIndex = value?.findIndex((item) => item[valueKey] === 'all');
        return (
          <Chip
            {...getTagProps({ index: isAllIndex })}
            label={numTags > 1 ? `All (${numTags - 1})` : 'All'}
          />
        );
      }
      if (numTags <= limitTags) {
        return (
          value.map((option, index) => {
            const labelVal = option[labelKey];
            const getLabelVal = () => {
              if (labelVal) return labelVal;
              const ownerStateOption = ownerState.options?.find((ownerOption) => ownerOption[valueKey] === option[valueKey]);
              if (ownerStateOption) return ownerStateOption[labelKey];
              return '';
            };
            return (
              <Chip
                {...getTagProps({ index })}
                key={index}
                label={getLabelVal()}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              />
            );
          })
        );
      }
      return (
        <Chip
          key="selectedItems"
          label={`Selected: ${numTags}`}
          sx={{
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
          }}
        />
      );
    }

    return (
      value.map((option, index) => {
        const labelVal = option[labelKey];
        const getLabelVal = () => {
          if (labelVal) return labelVal;
          const ownerStateOption = ownerState.options?.find((ownerOption) => ownerOption[valueKey] === option[valueKey]);
          if (ownerStateOption) return ownerStateOption[labelKey];
          return '';
        };
        return (
          <Chip
            {...getTagProps({ index })}
            key={index}
            label={getLabelVal()}
            sx={{
              height: 'auto',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
              },
            }}
          />
        );
      })
    );
  }, [renderTags, limitTags]);

  const onChangeInternal = useCallback((_event, newValue, reason) => {
    if (reason === 'clear' && _event.type === 'click') onClear && onClear();
    if (onlyOption && onlyOptionRef.current !== null) {
      newValue = [onlyOptionRef.current];
      onChange(newValue);
      onlyOptionRef.current = null;
      return;
    }

    if (!allOption) {
      onChange(newValue);
      return;
    }

    const allInCurrentValue = value.find((val) => val[valueKey] === 'all');
    const allInNewValue = newValue.find((val) => val[valueKey] === 'all');

    // all selected
    if (!allInCurrentValue && allInNewValue) {
      newValue = [{ [labelKey]: 'All', [valueKey]: 'all' }, ...options];
      onChange(newValue);
      return;
    }

    // all unselected
    if (allInCurrentValue && !allInNewValue) {
      newValue = [];
      onChange(newValue);
      return;
    }

    // something else selected
    if (allInCurrentValue
      && newValue.filter((val) => val[valueKey] !== 'all').length < options.length
    ) {
      newValue = newValue.filter((val) => val[valueKey] !== 'all');
      onChange(newValue);
      return;
    }
    // everything is selected but all
    if (!allInCurrentValue
      && newValue.filter((val) => val[valueKey] !== 'all').length === options.length
    ) {
      newValue = [{ [labelKey]: 'All', [valueKey]: 'all' }, ...options];
      onChange(newValue);
      return;
    }
    onChange(newValue);
  }, [options, value]);

  useEffect(() => {
    if (!allOption) return;
    const allInCurrentValue = value.find((val) => val[valueKey] === 'all');
    if (allInCurrentValue && value.length !== (options.length + 1)) {
      onChange([{ [labelKey]: 'All', [valueKey]: 'all' }, ...options]);
    }
  }, [value, options]);

  return (
    <MUIAutocomplete
      options={optionsToRender}
      groupBy={groupBy}
      limitTags={limitTags || -1}
      renderGroup={(params) => (
        <li
          key={params.key}
        >
          <GroupHeader theme={theme}>
            {getGroupLabel
              ? getGroupLabel(params.group)
              : params.group}
          </GroupHeader>
          <GroupItems>
            {params?.children}
          </GroupItems>
        </li>
      )}
      getOptionLabel={getOptionLabel || ((option) => (option[labelKey] ? option[labelKey] : ''))}
      getOptionDisabled={getOptionDisabled || ((option) => option?.isDisabled)}
      isOptionEqualToValue={(option, value) => {
        if (isOptionEqualToValue) {
          return isOptionEqualToValue(option, value);
        }
        return option === value;
      }}
      disabled={isDisabled}
      readOnly={isReadOnly}
      placeholder={placeholder}
      disableCloseOnSelect={disableCloseOnSelect}
      value={value}
      onChange={onChangeInternal}
      onInputChange={onInputChange}
      loading={loading}
      noOptionsText={noOptionsText}
      renderTags={onRenderTags}
      getOptionKey={(option) => (option.id || option.code || option[valueKey])}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
        >
          {multiple && checkbox && (
            <Checkbox
              icon={UncheckedCheckboxIcon}
              checkedIcon={CheckedCheckboxIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          <ListItemText
            sx={onlyOption ? {
              marginTop: '0px',
              marginBottom: '0px',
              span: {
                lineHeight: 'normal',
              },
              marginRight: '8px',
            } : {
              marginTop: '0px',
              marginBottom: '0px',
              span: {
                lineHeight: 'normal',
              },
            }}
            primaryTypographyProps={{ style: { whiteSpace: 'normal', wordWrap: 'break-word' } }}
            primary={getOptionInListLabel ? getOptionInListLabel(option) : option[labelKey]}
          />
          {onlyOption && option[valueKey] !== 'all' && (
          <Button
            variant="contained"
            sx={{
              padding: '0px',
            }}
            onClick={() => {
              onlyOptionRef.current = option;
            }}
          >
            {t('select.only')}
          </Button>
          )}
        </MenuItem>
      )}
      inputValue={multiple ? inputValue : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          helperText={error}
          variant="filled"
          onChange={multiple ? (e) => setInputValue(e.target.value) : undefined}
        />
      )}
      sx={{
        '& .MuiInputLabel-root': {
          color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
        },
      }}
      disableClearable={disableClearable}
      multiple={multiple}
      fullWidth
      onClose={onClose}
      onOpen={onOpen}
      size={size}
      {...rest}
    />
  );
}

const GroupHeader = styled.div`
  position: sticky;
  top: -8px;
  padding: 4px 10px;
  background: ${(props) => props.theme.palette.background.default};
  z-index: 1;
`;

const GroupItems = styled.ul`
  padding: 0;
`;
