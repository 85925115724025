import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Chip,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { StaticDateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useFormState, useForm } from 'react-final-form';
import moment from 'moment';
import { MUIGetDateInCurrentTimestamp } from '../../utils/dates';

export default function DateRangeWithShortcuts({
  value,
  onChange,
  minDate = moment('01/01/1900'),
  disableFuture,
  shortcuts,
  buttons,
}) {
  if (!Array.isArray(value)) value = [null, null];
  const formState = useFormState();
  const form = useForm();
  const { t } = useTranslation('platformDashboards');
  const { t: tgErr } = useTranslation('general', { keyPrefix: 'errors' });
  const [startErrorMessage, setStartErrorMessage] = useState(null);
  const [endErrorMessage, setEndErrorMessage] = useState(null);
  const [selectedShortcut, setSelectedShortcut] = useState(formState.values?.dateRangeOption);
  const [internalValue, setInternalValue] = useState(value);
  const [selectedCustomUnit, setSelectedCustomUnit] = useState(formState.values?.customUnit);
  const [selectedCustomNumber, setSelectedCustomNumber] = useState(formState?.values?.customNumber);

  const getDateRangeFromDuration = (durationValue, number, unit) => {
    if (durationValue === 'fixed') {
      const newStartDate = moment.utc().subtract(14, 'days').format('YYYY-MM-DD');
      const newEndDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
      return ([newStartDate, newEndDate]);
    }
    if (durationValue === 'custom') {
      const newStartDate = moment.utc().subtract(number, unit).format('YYYY-MM-DD');
      const newEndDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
      return ([newStartDate, newEndDate]);
    }
    const duration = moment.duration(durationValue);
    const newStartDate = moment.utc().subtract(duration).format('YYYY-MM-DD');
    const newEndDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
    return ([newStartDate, newEndDate]);
  };

  const renderButton = (button, index) => {
    const handleButtonOnClick = () => {
      if (button.type === 'accept') {
        if (selectedShortcut === 'custom') {
          form.change('customUnit', selectedCustomUnit);
          form.change('customNumber', selectedCustomNumber);
        }
        form.change('dateRangeOption', selectedShortcut);
        onChange(internalValue);
      }
      button.onClick();
    };
    return (
      <Button
        key={`modal-button-${index}`}
        variant="contained"
        onClick={() => handleButtonOnClick()}
        sx={{
          minWidth: '110px',
        }}
      >
        {button.label}
      </Button>
    );
  };

  const handleShortCutClick = (shortcut) => {
    setSelectedShortcut(shortcut.value);
    const newInternalDateRange = getDateRangeFromDuration(shortcut.value, selectedCustomUnit, selectedCustomNumber);
    setInternalValue(newInternalDateRange);
  };
  const renderShortcut = (shortcut, index) => {
    if (shortcut.value === 'custom') {
      return (
        <>
          <Chip
            key={index}
            label={shortcut.label}
            onClick={() => handleShortCutClick(shortcut)}
            color={selectedShortcut === shortcut.value ? 'primary' : 'default'}
            sx={{
              width: 200,
            }}
          />
          {selectedShortcut === 'custom' ? (
            <>
              <TextField
                id="customNumberInternal"
                key="customNumber"
                type="number"
                value={selectedCustomNumber}
                placeholder={0}
                onChange={(event) => {
                  setSelectedCustomNumber(event.target.value);
                  const newInternalDateRange = getDateRangeFromDuration(shortcut.value, event.target.value, selectedCustomUnit);
                  setInternalValue(newInternalDateRange);
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 540,
                  },
                }}
                variant="filled"
                label="Number"
                sx={{
                  marginTop: '8px',
                  width: 200,
                }}
                size="small"
              />
              <Box
                mt={1}
                width={200}
              >
                <FormControl fullWidth>
                  <InputLabel id="customUnitInternal">Unit</InputLabel>
                  <Select
                    key="customUnit"
                    labelId="customUnitInternal"
                    id="customUnitInternal"
                    label="Unit"
                    value={selectedCustomUnit}
                    onChange={(event) => {
                      setSelectedCustomUnit(event.target.value);
                      const newInternalDateRange = getDateRangeFromDuration(shortcut.value, selectedCustomNumber, event.target.value);
                      setInternalValue(newInternalDateRange);
                    }}
                    size="small"
                  >
                    <MenuItem value="days">{t('fieldLabels.days')}</MenuItem>
                    <MenuItem value="weeks">{t('fieldLabels.weeks')}</MenuItem>
                    <MenuItem value="months">{t('fieldLabels.months')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : null}
        </>
      );
    }
    return (
      <Chip
        key={index}
        label={shortcut.label}
        onClick={() => handleShortCutClick(shortcut)}
        color={selectedShortcut === shortcut.value ? 'primary' : 'default'}
        sx={{
          width: 200,
          marginBottom: '8px',
        }}
      />
    );
  };
  const renderedShortcuts = useMemo(() => {
    if (!shortcuts || shortcuts?.length === 0) return null;
    return shortcuts.map(renderShortcut);
  }, [shortcuts, selectedShortcut, selectedCustomNumber, selectedCustomUnit]);

  const handleInternalValueChange = (value, errors) => {
    if (errors.validationError.every((item) => item === null)) {
      setInternalValue(value.map((val) => val && moment.utc(val.toString().split(' GMT')[0])));
    }
  };
  const handleError = ([startErr, endErr]) => {
    if (startErr) {
      setStartErrorMessage(tgErr(startErr, { minDate: moment(minDate).format('L') }));
    } else {
      setStartErrorMessage(null);
    }
    if (endErr) {
      setEndErrorMessage(tgErr(endErr, { minDate: moment(minDate).format('L') }));
    } else {
      setEndErrorMessage(null);
    }
  };
  const handleHelperText = useMemo(() => {
    if (startErrorMessage && endErrorMessage) return `${startErrorMessage} - ${endErrorMessage}`;
    if (startErrorMessage) return startErrorMessage;
    if (endErrorMessage) return endErrorMessage;
    return undefined;
  }, [startErrorMessage, endErrorMessage]);
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        sx={{
          backgroundColor: 'rgb(35, 35, 58)',
          backgroundImage: 'none',
          padding: '16px',
        }}
      >
        <Box
          width="100%"
          display="flex"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width={250}
          >
            {renderedShortcuts}
          </Box>
          <Box
            width="calc(100% - 200px)"
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
            >
              <StaticDateRangePicker
                calendars={2}
                value={internalValue.map((val) => MUIGetDateInCurrentTimestamp(val))}
                onChange={handleInternalValueChange}
                onRangePositionChange={() => (selectedShortcut !== 'fixed' ? setSelectedShortcut('fixed') : {})}
                onError={handleError}
                minDate={minDate}
                disableFuture={disableFuture}
                slotProps={{
                  textField: {
                    helperText: handleHelperText,
                  },
                  toolbar: {},
                  actionBar: {
                    hidden: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box>
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            mt={1}
            direction="row"
            justifyContent="flex-end"
          >
            {buttons && buttons.map(renderButton)}
          </Stack>
        </Box>
      </Card>
    </Box>
  );
}
