import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Resizable } from 'react-resizable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const MyHandle = React.forwardRef((props, ref) => {
  const { handleAxis, ...restProps } = props;
  return (
    <DragIndicatorIcon
      ref={ref}
      className={`foo handle-${handleAxis}`}
      sx={{
        position: 'absolute',
        width: '30px',
        height: '30px',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%) rotate(90deg)',

      }}
      {...restProps}
    />
  );
});

export const ResizeableContainer = ({
  children,
  axis = 'y',
  initialWidth = '100%',
  initialHeight = 300,
  onResizeEndCallback,
}) => {
  const [params, setParams] = useState({ width: initialWidth, height: initialHeight });
  const onResize = (event, { size }) => {
    setParams((prevState) => ({ ...prevState, width: size.width, height: size.height }));
  };
  const onResizeStop = (event, { size }) => {
    if (onResizeEndCallback) onResizeEndCallback(size);
  };
  return (
    <Resizable
      height={params.height}
      width={params.width}
      onResize={onResize}
      onResizeStop={onResizeStop}
      handle={<MyHandle />}
      axis={axis}
      style={{ position: 'relative' }}
    >
      <Box
        width={params.width}
        height={params.height}
      >
        <Box
          width="100%"
          height="calc(100% - 30px)"
          p={2}
        >
          {children ? React.cloneElement(children, {}) : null }
        </Box>
      </Box>
    </Resizable>
  );
};
